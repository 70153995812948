import { useEffect } from 'react'
import { useRouter } from 'next/router'
import type { NextPageWithLayout } from '@/types'
import { useIsIndie } from '@/utils/use-is-indie'
import AppHead from '@/shared/head'

const HomePage: NextPageWithLayout = () => {
  const router = useRouter()
  const isIndie = useIsIndie()

  useEffect(() => {
    if (isIndie !== undefined) {
      const clientDefaultPageURL = isIndie
        ? '/current/promotions'
        : '/current/campaigns'

      router.replace(clientDefaultPageURL)
    }
  }, [isIndie, router])

  return (
    <>
      <AppHead subtitle="Accueil"></AppHead>
      <p role="progressbar">Chargement...</p>
    </>
  )
}

export default HomePage
